import axiosConfig from "@/api/axiosConfig.js";

const dataProvider = {
  async getLoginDetails(CarrierPortalUserId) {
    var result = [];
    await axiosConfig
      .get("/WebAuth/dologin", { CarrierPortalUserId })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async getAvailableLoads(
    CarrierID,
    CarrierPortalUserID,
    EmailAddress,
    OriginCity,
    OriginState,
    OriginRadius,
    DestinationCity,
    DestinationState,
    DestinationRadius,
    PickupDate,
    Van,
    Flatbed,
    Reefer,
    Specialized,
    Size48,
    Size53
  ) {
    var result = [];
    await axiosConfig
      .post("/loads/available-loads", {
        CarrierID,
        CarrierPortalUserID,
        EmailAddress,
        OriginCity,
        OriginState,
        OriginRadius,
        DestinationCity,
        DestinationState,
        DestinationRadius,
        PickupDate,
        Van,
        Flatbed,
        Reefer,
        Specialized,
        Size48,
        Size53,
      })
      .then((res) => {
        result = res.data;
      });
    return result;
  },

  async getFactoringCompanies() {
    var result = [];
    await axiosConfig
      .get("/loads/factoring-companies")
      .then((res) => {
        result = res.data;
      });
    return result;
  },

  async generateInvoice(
    InvoiceData
  ) {
    var result = [];
    await axiosConfig
      .post("/loads/generate-invoice", {
        invoiceData: InvoiceData[0]
      })
      .then((res) => {
        result = res.data;
      });
    return result;
  },

  async getMyLoads(CarrierID, CarrierPortalUserID, EmailAddress) {
    var result = [];
    await axiosConfig
      .post("/loads/my-loads", { CarrierID, CarrierPortalUserID, EmailAddress })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async getRecentLoads(CarrierID, CarrierPortalUserID, EmailAddress) {
    var result = [];
    await axiosConfig
      .post("/home/recent-search", {
        CarrierID,
        CarrierPortalUserID,
        EmailAddress,
      })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async getRecentPayment(CarrierID, CarrierPortalUserID, EmailAddress) {
    var result = [];
    await axiosConfig
      .post("/home/recent-payment", {
        CarrierID,
        CarrierPortalUserID,
        EmailAddress,
      })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async getFTLDetails(LoadID, MCUSNumber) {
    var result = [];
    await axiosConfig
      .post("/payment/ftl-status", { LoadID, MCUSNumber })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async getLTLDetails(
    LoadID,
    ProNumber,
    BolCode,
    PaymentStatus,
    Name,
    CompanyName,
    PhoneNumber,
    CarrierID,
    CarrierPortalUserID,
    EmailAddress
  ) {
    var result = [];
    await axiosConfig
      .post("/payment/ltl-status", {
        LoadID,
        ProNumber,
        BolCode,
        PaymentStatus,
        Name,
        CompanyName,
        PhoneNumber,
        CarrierID,
        CarrierPortalUserID,
        EmailAddress,
      })
      .then((res) => {
        result = res.data;
      });

    return result;
  },
  async getFactoringCompanyDetails(
    MonthsBack,
    PaymentStatus,
    CarrierID,
    MCUSNumber,
    Name,
    CompanyName,
    PhoneNumber,
    CarrierPortalUserID,
    EmailAddress
  ) {
    var result = [];
    await axiosConfig
      .post("/payment/factoring-status", {
        MonthsBack,
        PaymentStatus,
        CarrierID,
        MCUSNumber,
        Name,
        CompanyName,
        PhoneNumber,
        CarrierPortalUserID,
        EmailAddress,
      })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async postMyProfile(
    CarrierPortalUserID,
    FirstName,
    LastName,
    PhoneNumber,
    EmailAddress,
    SubscribeToELoad,
    oldpassword,
    password
  ) {
    var result = [];
    await axiosConfig
      .post("/webauth/user", {
        CarrierPortalUserID,
        FirstName,
        LastName,
        PhoneNumber,
        EmailAddress,
        SubscribeToELoad,
        oldpassword,
        password,
      })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async postEmailAddress(EmailAddress) {
    var result = [];

    await axiosConfig
      .post("/webauth/forgot-password", { EmailAddress })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async getUploadDetails(
    CarrierID,
    CarrierPortalUserID,
    EmailAddress,
    LoadID,
    MCUSNumber
  ) {
    var result = [];
    await axiosConfig
      .post("/loads/load-details", {
        CarrierID,
        CarrierPortalUserID,
        EmailAddress,
        LoadID,
        MCUSNumber,
      })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
  async postUploadDetails(form) {
    var result = [];
    await axiosConfig
      .post("/loads/submit-documents", {
        CarrierID: form.CarrierID,
        CarrierPortalUserID: form.CarrierPortalUserID,
        EmailAddress: form.EmailAddress,
        Documents: form.Documents,
        InvoicBase64String: form.InvoicBase64String,
        PaymentType: form.PaymentType,
        LoadID: form.LoadID,
        ContactName: form.ContactName,
        ContactEmail: form.ContactEmail,
        SendInvoice: form.SendInvoice,
        ContactPhone: form.ContactPhone,
        CarrierName: form.CarrierName,
      })
      .then((res) => {
        result = res.data;
      });
    return result;
  },
};

export { dataProvider };
